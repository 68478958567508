import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import '../styles/homepage.scss';

function getSuffix(d) {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1: return "st";
    case 2: return "nd";
    case 3: return "rd";
    default: return "th";
  }
}

function getDate() {
  var date = new Date();
  var monthNumber = date.getMonth();
  var day = date.getDate();
  var month = "";
  switch (monthNumber) {
    case 0: {
      month = "January";
      break;
    }
    case 1: {
      month = "February";
      break;
    }
    case 2: {
      month = "March";
      break;
    }
    case 3: {
      month = "April";
      break;
    }
    case 4: {
      month = "May";
      break;
    }
    case 5: {
      month = "June";
      break;
    }
    case 6: {
      month = "July";
      break;
    }
    case 7: {
      month = "August";
      break;
    }
    case 8: {
      month = "September";
      break;
    }
    case 9: {
      month = "October";
      break;
    }
    case 10: {
      month = "November";
      break;
    }
    case 11: {
      month = "December";
      break;
    }
  }
  return month + " " + day + getSuffix(day);
}

// 1/1/2022
const START_TIME = 1640995200;

function getDateBasedArticleLink() {
  var currentDateOffset = Math.round(new Date().getTime() / 1000) - START_TIME;
  var days = Math.round(currentDateOffset / 86400);
  return "Article #" + days + "!";
  // return (<Link to="">{currentDateOffset} and {days}</Link>);
}

const IndexPage = () => {
  return (
    <Layout pageTitle="Home Page">
      <div className="top-bar">
        <h1 className="title">The Learning Database</h1>
      </div>
      <div className="row callout-box-row">
        <div className="col-sm callout-box box-1">
          <p>10 Categories</p>
        </div>
        <div className="col-sm callout-box box-2">
          <p>30 Topics</p>
        </div>
        <div className="col-sm callout-box box-3">
          <p><Link to="/topics/">Explore them all...</Link></p>
        </div>
      </div>
      <div className="row home-content">
        <div className="col-sm-8">
          <h1>Learn something new every day!</h1>
          <p>There's so much in the world to learn about! The Learning DB was created to be a resource for sharing knowledge.
            Whether that knowlege is shared through articles written specifically for LearningDB, or through links to other
            educational websites, LearningDB has you covered!</p>
          <h2>I need help studying for an upcoming test</h2>
          <p>Whether you're studying for a test, an AP®︎ Exam, or doing your math homework, you can start here! Browse
            our <Link to="/topics/">Topics</Link> page to find what you're looking for.</p>
          <h2>I'm in college/university, not high school</h2>
          <p>Not a problem! LearningDB is for people of all age ranges and educational backgrounds. Some resources recommend
            prior knowledge, but most are written for a general audience.</p>
          <h2>I'm looking to dive deep into a specific topic</h2>
          <p>Fantastic! LearningDB can be a great starting point for your research. While this website may not have all the
            answers you're looking for, you'll be better prepared to conduct your own research elsewhere on the internet.</p>
        </div>
        <div className="col-sm">
          <div className="row">
            <p>Today's date is {getDate()}</p>
            <p>What a great day to learn about...</p>
            <p><b>{getDateBasedArticleLink()}</b></p>
          </div>
        </div>
      </div>
    </Layout>)
}

export default IndexPage
